import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";
import i18next from "i18next";

class Clients extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_clients"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            addNewClient: false,
            editClientsForm: false,
            assignCampaign: false,
            handleDeletePopup: false,
            selectedOption: {value: '',},
            assignCampaignOptions: [],
            assignCampaignOptionsValue: [],
            clientsFullName: '',
            clientsFullNameErr: '',
            clientsTelegramErr: '',
            clientsEmail: '',
            clientsEmailErr: '',
            clientsPhone: '',
            clientsPhoneErr: '',
            clientsPassword: '',
            clientsPasswordErr: '',
            newClientOpeAlert: false,
            newClientOpeAlertVariant: '',
            newClientOpeAlertMessage: '',
            commonAlert: false,
            commonAlertVariant: '',
            commonAlertMessage: '',
            clientsData: [],
            columns: [],
            options: [],
            uuid: '',
            assignUuid: '',
            selectCampaignErr: '',
            assignedCampaignList: [],
            removeClientCampaign: '',
            removeClientCampaignModal: false,
            dataLoader: true,
            cityOptions: [],
            cityOptionsErr: "",
            cityOptionSelected: [],
        }
        document.title = this.props.pageTitle;
        this.handleClose = this.handleClose.bind(this)
        this.submitClientsForm = this.submitClientsForm.bind(this)
        this.assignCampaignToClient = this.assignCampaignToClient.bind(this)
        this.handleDeleteClient = this.handleDeleteClient.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.matchRegularExpression = this.matchRegularExpression.bind(this)
        this.removeAssignedCampaign = this.removeAssignedCampaign.bind(this)
        this.loadClientAssignedCampaigns = this.loadClientAssignedCampaigns.bind(this)
    }

    componentDidMount() {
        this.loadAllClients()
        this.props.loadFooterHeight()
        if (!this.props.permissionList) {
            document.querySelector("#content-body").innerHTML = "<div class='container-fluid'>" +
                "<div class='form-input-content text-center error-page'>\n" +
                "    <h1 class='error-text font-weight-bold'>404</h1>\n" +
                "    <h4><i class='fa fa-exclamation-triangle text-warning'></i>" + i18next.t("page_not_found") + "</h4>\n" +
                "    <p>" + i18next.t("mistyped_address") + "</p>\n" +
                "</div>" +
                "</div>"
        }
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
    }

    handleChange = (selectedOption) => {
        this.setState({selectedOption});
    };

    handleClose() {
        this.setState({
            addNewClient: false,
            clientsFullName: "",
            clientsEmail: "",
            clientsPhone: "",
            clientsTelegram: "",
            assignCampaign: false,
            editClientsForm: false,
            handleDeletePopup: false,
            clientsFullNameErr: '',
            clientsTelegramErr: '',
            clientsEmailErr: '',
            clientsPhoneErr: '',
            clientsPasswordErr: '',
            commonAlert: false,
            newClientOpeAlert: false,
            newClientOpeAlertVariant: '',
            newClientOpeAlertMessage: '',
            assignUuid: '',
            selectCampaignErr: '',
            selectedOption: {value: '',},
            assignedCampaignList: [],
            cityOptionsErr: "",
            cityOptionSelected: [],
        })
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    loadClientAssignedCampaigns(userUuid) {
        PostData('/frontCall/clients/getCampaigns', {client_uuid: userUuid})
            .then(result => {
                let assignedCampaignList = ""
                if (result.response.length > 0) {
                    assignedCampaignList = result.response.map((data) =>
                        <Button variant={"primary"} className={"mr-2 mb-2"} onClick={() =>
                            this.setState({
                                removeClientCampaign: data.uuid,
                                removeClientCampaignModal: true,
                            })
                        } key={data.uuid}>{data.title} <span className="text-danger ml-1">X</span></Button>
                    )
                } else {
                    assignedCampaignList = <p className='text_primary text-center'>{i18next.t("no_campaign_assigned")}</p>
                }
                this.setState({
                    assignedCampaignList: assignedCampaignList
                })
            })
    }

    loadAllClients() {
        //loadCampaigns.
        PostData('/frontCall/campaigns/get_all_campaigns')
            .then(result => {
                let optionsValue = [];
                result.response.map(function (data) {
                    optionsValue.push({value: data.uuid, label: data.title})
                    return 0;
                })
                this.setState({options: optionsValue})
            })
        // Load Cities.
        PostData("/frontCall/cities/getAll")
            .then(result => {
                let cityOptions = []
                result.response.map(data => {
                    cityOptions.push({label: data.name, value: data.uuid})
                })
                this.setState({cityOptions: cityOptions})
            })
        PostData("/frontCall/clients/getAllClients")
            .then(result => {
                this.setState({
                    clientsData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("full_name")}</h6>,
                            selector: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("e_mail")}</h6>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("phone")}</h6>,
                            selector: (row) => row.phone,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("telegram_username")}</h6>,
                            selector: (row) => row.telegram_handle,
                            sortable: true
                        },
                        ...(this.state.granularPermissions.includes('a')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("assign_campaign")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.loadClientAssignedCampaigns(row.uuid)
                                this.setState({
                                    assignCampaign: true,
                                    assignUuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-smartphone-3"></em>
                            </Button>
                        }] : [],
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                            cell: (data) => <Button variant="primary" className="my-2" onClick={() => {
                                let cityOptionSelected = []
                                data.cities.map(item => cityOptionSelected.push({label: item.name, value: item.uuid}))
                                this.setState({
                                    addNewClient: true,
                                    editClientsForm: true,
                                    clientsFullName: data.full_name,
                                    clientsEmail: data.email,
                                    clientsPhone: data.phone,
                                    clientsTelegram: data.telegram_handle,
                                    uuid: data.uuid,
                                    cityOptionSelected: cityOptionSelected
                                })
                            }}>
                                <em className="flaticon-381-edit"></em>
                            </Button>
                        }] : [],
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (data) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    uuid: data.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"></em>
                            </Button>
                        }] : [],
                    ],
                })
            })
    }

    matchRegularExpression(phoneNumber) {
        return phoneNumber.match("^(\\+7|7|8)?[\\s\\-]?\\(?[489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$")
    }

    submitClientsForm() {
        let formData = {}
            formData.full_name = formData.email = formData.phone = formData.telegram_handle = formData.password = ""
            formData.city_id = []
        if (this.state.clientsFullName === "") {
            this.setState({clientsFullNameErr: i18next.t("enter_full_name")})
        } else {
            this.setState({clientsFullNameErr: ""})
            formData.full_name = this.state.clientsFullName
        }
        if (this.state.clientsEmail === "") {
            this.setState({clientsEmailErr: i18next.t("enter_email")})
        } else if (!this.state.clientsEmail.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            this.setState({clientsEmailErr: i18next.t("enter_correct_email")})
        } else {
            this.setState({clientsEmailErr: ""})
            formData.email = this.state.clientsEmail
        }
        if (this.state.clientsPhone === "") {
            this.setState({clientsPhoneErr: i18next.t("enter_phone")})
        } else if (!this.matchRegularExpression(this.state.clientsPhone)) {
            this.setState({clientsPhoneErr: i18next.t("enter_correct_phone")})
        } else {
            this.setState({clientsPhoneErr: ""})
            formData.phone = this.state.clientsPhone
        }
        if (this.state.clientsTelegram === "") {
            this.setState({clientsTelegramErr: i18next.t("enter_telegram_username")})
        } else {
            this.setState({clientsTelegramErr: ""})
            formData.telegram_handle = this.state.clientsTelegram
        }
        if (!this.state.editClientsForm && this.state.clientsPassword === "") {
            this.setState({clientsPasswordErr: i18next.t("enter_password")})
        } else {
            this.setState({clientsPasswordErr: ""})
            formData.password = this.state.clientsPassword
        }
        if (Object.keys(this.state.cityOptionSelected).length === 0) {
            this.setState({cityOptionsErr: i18next.t("select_city")})
        } else {
            this.setState({cityOptionsErr: ""})
            this.state.cityOptionSelected.map((data, index) => {
                formData.city_id[index] = data.value
            })
        }

        if (this.state.editClientsForm) {formData.uuid = this.state.uuid}
        let postApi = this.state.editClientsForm ? "/frontCall/clients/updateClient" : "/frontCall/clients/addClient";
        if(formData.full_name !== "" && formData.email !== "" && formData.phone !== ""
            && formData.telegram_handle !== "" && Object.keys(formData.city_id).length > 0) {
            PostData(postApi, formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            addNewClient: false,
                            newClientOpeAlert: false,
                            newClientOpeAlertVariant: "",
                            newClientOpeAlertMessage: "",
                            commonAlert: true,
                            commonAlertVariant: "success",
                            commonAlertMessage: this.state.editClientsForm ? i18next.t("client_data_updated") : i18next.t("client_added")
                        })
                        this.loadAllClients()
                        setTimeout(() => this.handleClose(), 5000);
                    } else {
                        this.setState({
                            newClientOpeAlert: true,
                            newClientOpeAlertVariant: "danger",
                            newClientOpeAlertMessage: result.messages.message
                        })
                    }
                })
        }
    }

    assignCampaignToClient() {
        if (this.state.selectedOption.value === "") {
            this.setState({
                selectCampaignErr: i18next.t("please_select_campaign")
            })
        } else {
            const formData = {
                client_uuid: this.state.assignUuid,
                campaign_uuid: this.state.selectedOption.value
            }
            PostData("/frontCall/clients/assignCampaign", formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            newClientOpeAlert: true,
                            newClientOpeAlertVariant: "success",
                            newClientOpeAlertMessage: i18next.t("campaign_assigned_successfully")
                        })
                        this.loadClientAssignedCampaigns(this.state.assignUuid)
                        this.loadAllClients()
                        setTimeout(() => this.setState({newClientOpeAlert: false}), 5000);
                    } else {
                        this.setState({
                            newClientOpeAlert: true,
                            newClientOpeAlertVariant: "danger",
                            newClientOpeAlertMessage: i18next.t("choose_another_client_to_assign_campaign")
                        })
                    }
                })
        }
    }

    handleDeleteClient() {
        PostData("/frontCall/clients/deleteClient", {uuid: this.state.uuid})
            .then(result => {
                if (result.status) {
                    this.setState({
                        handleDeletePopup: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("client_removed"),
                    })
                }
            })
        this.loadAllClients()
        setTimeout(() => this.handleClose(), 5000);
    }

    removeAssignedCampaign() {
        PostData('/frontCall/clients/unAssignCampaign', {uuid: this.state.removeClientCampaign})
            .then(result => {
                this.setState({
                    newClientOpeAlert: true,
                    newClientOpeAlertVariant: "success",
                    newClientOpeAlertMessage: result.response,
                    removeClientCampaignModal: false,
                })
                setTimeout(() => this.setState({newClientOpeAlert: false}), 5000);
                this.loadClientAssignedCampaigns(this.state.assignUuid)
            })
    }

    render() {
        const {selectedOption} = this.state;
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Button variant={"primary"} className="mb-2 ml-2" onClick={() => {
                                this.setState({addNewClient: true})
                            }}>{i18next.t("add_new_client")}</Button>
                        </div>}
                        <Modal show={this.state.addNewClient} onHide={this.handleClose}
                               dialogClassName="modal-dialog-centered" backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editClientsForm ? i18next.t("edit_client") : i18next.t("add_client")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.newClientOpeAlert} variant={this.state.newClientOpeAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.newClientOpeAlertMessage}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group className="mb-3" controlId="clientFull_Name">
                                        <Form.Label>{i18next.t("enter_full_name")}</Form.Label>
                                        <Form.Control type="text" name="clientsFullName"
                                                      value={this.state.clientsFullName} onChange={this.onChangeInput}
                                                      placeholder={i18next.t("full_name")}/>
                                        {this.state.clientsFullNameErr && <span
                                            className="text-danger pl-3 pt-1">{this.state.clientsFullNameErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="clientEmail">
                                        <Form.Label>{i18next.t("enter_email")}</Form.Label>
                                        <Form.Control type="text" name="clientsEmail" value={this.state.clientsEmail}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("email")}/>
                                        {this.state.clientsEmailErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.clientsEmailErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="clientPhone">
                                        <Form.Label>{i18next.t("enter_phone")}</Form.Label>
                                        <Form.Control type="text" name="clientsPhone" value={this.state.clientsPhone}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("phone")}/>
                                        {this.state.clientsPhoneErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.clientsPhoneErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="clientTelegram">
                                        <Form.Label>{i18next.t("enter_telegram_username")}</Form.Label>
                                        <Form.Control type="text" name="clientsTelegram"
                                                      value={this.state.clientsTelegram} onChange={this.onChangeInput}
                                                      placeholder={i18next.t("telegram_username")}/>
                                        {this.state.clientsTelegramErr && <span
                                            className="text-danger pl-3 pt-1">{this.state.clientsTelegramErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="cityOptions">
                                        <Form.Label>{i18next.t("select_city")}</Form.Label>
                                        <Select value={this.state.cityOptionSelected} isMulti={true}
                                                defaultValue={this.state.cityOptionSelected}
                                                onChange={(cityOptionSelected) => {
                                                    this.setState({cityOptionSelected}, () =>
                                                        this.state.cityOptionSelected
                                                    );}}
                                                options={this.state.cityOptions}
                                        />
                                        {this.state.cityOptionsErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.cityOptionsErr}</div>}
                                    </Form.Group>
                                    {
                                        !this.state.editClientsForm &&
                                        <Form.Group className="mb-3" controlId="clientPassword">
                                            <Form.Label>{i18next.t("enter_password")}</Form.Label>
                                            <Form.Control type="password" name="clientsPassword"
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("password")}/>
                                            {this.state.clientsPasswordErr && <span
                                                className="text-danger pl-3 pt-1">{this.state.clientsPasswordErr}</span>}
                                        </Form.Group>
                                    }
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} className="light"
                                        onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"}
                                        onClick={this.submitClientsForm}>{i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.assignCampaign} onHide={this.handleClose}
                               dialogClassName="modal-dialog-centered" backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{i18next.t("assign_campaign_to_client")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.newClientOpeAlert} variant={this.state.newClientOpeAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.newClientOpeAlertMessage}</h6>
                                </Alert>
                                <div>
                                    {this.state.assignedCampaignList}
                                </div>
                                <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={this.state.options}
                                />
                                {this.state.selectCampaignErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.selectCampaignErr}</span>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} className="light"
                                        onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"}
                                        onClick={this.assignCampaignToClient}>{i18next.t("assign")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose}
                               dialogClassName="modal-dialog-centered" backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("remove_device_modal")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"}
                                        onClick={this.handleDeleteClient}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} className="light"
                                        onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.removeClientCampaignModal} onHide={this.handleClose}
                               dialogClassName="modal-dialog-centered" backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("remove_assign_campaign")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    this.removeAssignedCampaign()
                                    this.setState({
                                        removeClientCampaign: '',
                                        removeClientCampaignModal: false,
                                    })
                                }}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} className="light" onClick={() =>
                                    this.setState({
                                        removeClientCampaign: '',
                                        removeClientCampaignModal: false,
                                    })
                                }>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                               className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.clientsData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Clients;