import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";
import i18next from "i18next";

class Partners extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_partners"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            addNewPartner: false,
            editPartnersForm: false,
            partnersFullName: '',
            partnersFullNameErr: '',
            partnersTelegram: '',
            partnersTelegramErr: '',
            partnersEmail: '',
            partnersEmailErr: '',
            partnersPhone: '',
            partnersPhoneErr: '',
            partnersPassword: '',
            partnersPasswordErr: '',
            handleDeletePopup: false,
            newPartnerOpeAlert: false,
            newPartnerOpeAlertVariant: '',
            newPartnerOpeAlertMessage: '',
            commonAlert: false,
            commonAlertVariant: '',
            commonAlertMessage: '',
            partnersData: [],
            columns: [],
            options: [],
            uuid: '',
            selectCampaignErr: '',
            dataLoader: true,
            cityOptions: [],
            cityOptionsErr: "",
            cityOptionSelected: [],
        }
        document.title = this.props.pageTitle;
        this.handleClose = this.handleClose.bind(this)
        this.submitPartnersForm = this.submitPartnersForm.bind(this)
        this.handleDeletePartner = this.handleDeletePartner.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.matchRegularExpression = this.matchRegularExpression.bind(this)
    }

    componentDidMount() {
        this.loadAllPartners()
        this.props.loadFooterHeight()
        if (!this.props.permissionList) {
            document.querySelector("#content-body").innerHTML = "<div class='container-fluid'>" +
                "<div class='form-input-content text-center error-page'>\n" +
                "    <h1 class='error-text font-weight-bold'>404</h1>\n" +
                "    <h4><i class='fa fa-exclamation-triangle text-warning'></i>" + i18next.t("page_not_found") + "</h4>\n" +
                "    <p>" + i18next.t("mistyped_address") + "</p>\n" +
                "</div>" +
                "</div>"
        }
        setTimeout(() => this.setState({dataLoader: false}), 3000)
    }

    handleClose() {
        this.setState({
            addNewPartner: false,
            partnersFullName: "",
            partnersFullNameErr: '',
            partnersEmail: "",
            partnersEmailErr: '',
            partnersPhone: "",
            partnersPhoneErr: '',
            partnersTelegram: "",
            partnersTelegramErr: '',
            partnersPassword: '',
            partnersPasswordErr: '',
            editPartnersForm: false,
            handleDeletePopup: false,
            newPartnerOpeAlert: false,
            newPartnerOpeAlertVariant: '',
            newPartnerOpeAlertMessage: '',
            commonAlert: false,
            commonAlertVariant: '',
            commonAlertMessage: '',
            cityOptionsErr: "",
            cityOptionSelected: [],
        })
    }

    onChangeInput = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }

    loadAllPartners() {
        // Load Cities.
        PostData("/frontCall/cities/getAll")
            .then(result => {
                console.log(result)
                let cityOptions = []
                result.response.map(data => cityOptions.push({label: data.name, value: data.uuid}))
                this.setState({cityOptions: cityOptions})
            })
        PostData("/frontCall/partners/getAllPartners")
            .then(result => {
                this.setState({
                    partnersData: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("full_name")}</h6>,
                            selector: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("e_mail")}</h6>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("phone")}</h6>,
                            selector: (row) => row.phone,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("telegram_username")}</h6>,
                            selector: (row) => row.telegram_handle,
                            sortable: true
                        },
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                            cell: (data) => <Button variant="primary" className="my-2" onClick={() => {
                                let cityOptionSelected = []
                                data.cities.map(item => cityOptionSelected.push({label: item.name, value: item.uuid}))
                                this.setState({
                                    addNewPartner: true,
                                    editPartnersForm: true,
                                    uuid: data.uuid,
                                    partnersFullName: data.full_name,
                                    partnersEmail: data.email,
                                    partnersPhone: data.phone,
                                    partnersTelegram: data.telegram_handle,
                                    cityOptionSelected: cityOptionSelected
                                })
                            }}>
                                <em className="flaticon-381-edit"></em>
                            </Button>
                        }] : [],
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (data) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    uuid: data.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"></em>
                            </Button>
                        }] : [],
                    ],
                })
            })
    }

    matchRegularExpression(phoneNumber) {
        return phoneNumber.match("^(\\+7|7|8)?[\\s\\-]?\\(?[489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$")
    }

    submitPartnersForm() {
        let formData = {}
            formData.full_name = formData.email = formData.phone = formData.telegram_handle = formData.password = ""
            formData.city_id = []
        if (this.state.partnersFullName === "") {
            this.setState({partnersFullNameErr: i18next.t("enter_full_name")})
        } else {
            this.setState({partnersFullNameErr: ""})
            formData.full_name = this.state.partnersFullName
        }
        if (this.state.partnersEmail === "") {
            this.setState({partnersEmailErr: i18next.t("enter_email")})
        } else if (!this.state.partnersEmail.match("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")) {
            this.setState({partnersEmailErr: i18next.t("enter_correct_email")})
        } else {
            this.setState({partnersEmailErr: ""})
            formData.email = this.state.partnersEmail
        }
        if (this.state.partnersPhone === "") {
            this.setState({partnersPhoneErr: i18next.t("enter_phone")})
        } else if (!this.matchRegularExpression(this.state.partnersPhone)) {
            this.setState({partnersPhoneErr: i18next.t("enter_correct_phone")})
        } else {
            this.setState({partnersPhoneErr: ""})
            formData.phone = this.state.partnersPhone
        }
        if (this.state.partnersTelegram === "") {
            this.setState({partnersTelegramErr: i18next.t("enter_telegram_username")})
        } else {
            this.setState({partnersTelegramErr: ""})
            formData.telegram_handle = this.state.partnersTelegram
        }
        if (!this.state.editPartnersForm && this.state.partnersPassword === "") {
            this.setState({partnersPasswordErr: i18next.t("enter_password")})
        } else {
            this.setState({partnersPasswordErr: ""})
            formData.password = this.state.partnersPassword
        }
        if (Object.keys(this.state.cityOptionSelected).length === 0) {
            this.setState({cityOptionsErr: i18next.t("select_city")})
        } else {
            this.setState({cityOptionsErr: ""})
            this.state.cityOptionSelected.map((data, index) => {
                formData.city_id[index] = data.value
            })
        }

        if (this.state.editPartnersForm) {formData.uuid = this.state.uuid}
        let postApi = this.state.editPartnersForm ? "/frontCall/partners/updatePartner" : "/frontCall/partners/addPartner";
        if(formData.full_name !== "" && formData.email !== "" && formData.phone !== ""
            && (this.state.editPartnersForm === false ? formData.password !== "" : true)
            && formData.telegram_handle !== "" && Object.keys(formData.city_id).length > 0) {
            PostData(postApi, formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            addNewPartner: false,
                            newPartnerOpeAlert: false,
                            newPartnerOpeAlertVariant: "",
                            newPartnerOpeAlertMessage: "",
                            commonAlert: true,
                            commonAlertVariant: "success",
                            commonAlertMessage: this.state.editPartnersForm ? i18next.t("partner_data_updated") : i18next.t("partner_added")
                        })
                        this.loadAllPartners()
                        setTimeout(() => this.handleClose(), 5000);
                    } else {
                        this.setState({
                            newPartnerOpeAlert: true,
                            newPartnerOpeAlertVariant: "danger",
                            newPartnerOpeAlertMessage: result.messages.message
                        })
                    }
                })
        }
    }

    handleDeletePartner() {
        PostData("/frontCall/partners/deletePartner", {uuid: this.state.uuid})
            .then(result => {
                if (result.status) {
                    this.setState({
                        handleDeletePopup: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("partner_removed"),
                    })
                }
            })
        this.loadAllPartners()
        setTimeout(() => this.handleClose(), 5000);
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Button variant={"primary"} className="mb-2 ml-2" onClick={() => {
                                this.setState({addNewPartner: true})
                            }}>{i18next.t("add_new_partner")}</Button>
                        </div>}
                        <Modal show={this.state.addNewPartner} onHide={this.handleClose}
                               dialogClassName="modal-dialog-centered" backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editPartnersForm ? i18next.t("edit_partner") : i18next.t("add_partner")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.newPartnerOpeAlert} variant={this.state.newPartnerOpeAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.newPartnerOpeAlertMessage}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group className="mb-3" controlId="partnerFull_Name">
                                        <Form.Label>{i18next.t("enter_full_name")}</Form.Label>
                                        <Form.Control type="text" name="partnersFullName"
                                                      value={this.state.partnersFullName} onChange={this.onChangeInput}
                                                      placeholder={i18next.t("full_name")}/>
                                        {this.state.partnersFullNameErr && <span
                                            className="text-danger pl-3 pt-1">{this.state.partnersFullNameErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="partnerEmail">
                                        <Form.Label>{i18next.t("enter_email")}</Form.Label>
                                        <Form.Control type="text" name="partnersEmail" value={this.state.partnersEmail}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("email")}/>
                                        {this.state.partnersEmailErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.partnersEmailErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="partnerPhone">
                                        <Form.Label>{i18next.t("enter_phone")}</Form.Label>
                                        <Form.Control type="text" name="partnersPhone" value={this.state.partnersPhone}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("phone")}/>
                                        {this.state.partnersPhoneErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.partnersPhoneErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="partnerTelegram">
                                        <Form.Label>{i18next.t("enter_telegram_username")}</Form.Label>
                                        <Form.Control type="text" name="partnersTelegram"
                                                      value={this.state.partnersTelegram} onChange={this.onChangeInput}
                                                      placeholder={i18next.t("telegram_username")}/>
                                        {this.state.partnersTelegramErr && <span
                                            className="text-danger pl-3 pt-1">{this.state.partnersTelegramErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="cityOptions">
                                        <Form.Label>{i18next.t("select_city")}</Form.Label>
                                        <Select value={this.state.cityOptionSelected} isMulti={true}
                                                defaultValue={this.state.cityOptionSelected}
                                                onChange={(cityOptionSelected) => {
                                                    this.setState({cityOptionSelected}, () =>
                                                        this.state.cityOptionSelected
                                                    );}}
                                                options={this.state.cityOptions}
                                        />
                                        {this.state.cityOptionsErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.cityOptionsErr}</div>}
                                    </Form.Group>
                                    {
                                        !this.state.editPartnersForm &&
                                        <Form.Group className="mb-3" controlId="partnerPassword">
                                            <Form.Label>{i18next.t("enter_password")}</Form.Label>
                                            <Form.Control type="password" name="partnersPassword"
                                                          onChange={this.onChangeInput}
                                                          placeholder={i18next.t("password")}/>
                                            {this.state.partnersPasswordErr && <span
                                                className="text-danger pl-3 pt-1">{this.state.partnersPasswordErr}</span>}
                                        </Form.Group>
                                    }
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} className="light"
                                        onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"}
                                        onClick={this.submitPartnersForm}>{i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose}
                               dialogClassName="modal-dialog-centered" backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("remove_partner_modal")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"}
                                        onClick={this.handleDeletePartner}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} className="light"
                                        onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                               className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.partnersData}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Partners;