import React from 'react'
import './style.css';
import $ from "jquery";
import i18next from "i18next";
import {Alert, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {PostDataRaw} from "../api_key/PostData";
import {Helmet} from "react-helmet";

class Promoter extends React.Component {
    constructor(props) {
        let cityCookie = {}
        if (localStorage.getItem('selectedCity') !== null) {
            cityCookie = JSON.parse(localStorage.getItem('selectedCity'));
        }
        super(props);
        this.state = {
            cityCookieValue: cityCookie,
            commonAlert: false,
            commonAlertVariant: "",
            commonAlertMessage: "",
            promoterSuccess: {
                show: false,
                variant: "",
                message: "",
            }
        }

        this.becomeAPromoter = this.becomeAPromoter.bind(this)
        this.becomeAPromoter1 = this.becomeAPromoter1.bind(this)
        this.handleSubmitPromotersForm = this.handleSubmitPromotersForm.bind(this)
    }

    handleSubmitPromotersForm(deviceName) {
        let become_a_promoter_name
        let become_a_promoter_telephone
        let become_a_promoter_email

        if (deviceName === "desktop") {
            become_a_promoter_name = $("#become_a_promoter-name")
            become_a_promoter_telephone = $("#become_a_promoter-telephone")
            become_a_promoter_email = $("#become_a_promoter-email")
        } else if (deviceName === "mobile") {
            become_a_promoter_name = $("#become_a_promoter-name1")
            become_a_promoter_telephone = $("#become_a_promoter-telephone1")
            become_a_promoter_email = $("#become_a_promoter-email1")
        } else {
            alert("Choose Device Type")
        }
        // let googleCaptchaResponse = $("#recaptcha-token")

        if (become_a_promoter_name.val() === "" || become_a_promoter_telephone.val() === "" || become_a_promoter_email.val() === "") {
            if (become_a_promoter_name.val() === "") {
                this.setState({become_a_promoter_nameErr: "Enter Name"})
            } else {
                this.setState({become_a_promoter_nameErr: ""})
            }
            if (become_a_promoter_telephone.val() === "") {
                this.setState({become_a_promoter_telephoneErr: "Enter Telephone"})
            } else {
                this.setState({become_a_promoter_telephoneErr: ""})
            }
            if (become_a_promoter_email.val() === "") {
                this.setState({become_a_promoter_emailErr: "Enter Email"})
            } else {
                this.setState({become_a_promoter_emailErr: ""})
            }
        } else {
            let formData = {
                city_uuid: this.state.cityCookieValue.uuid,
                city_name: this.state.cityCookieValue.name,
                zipcode: this.state.cityCookieValue.zipcode,
                contact_us_email: this.state.cityCookieValue.contact_us_email,
                contact_us_phone: this.state.cityCookieValue.contact_us_phone,
                latitude: this.state.cityCookieValue.latitude,
                longitude: this.state.cityCookieValue.longitude,
                name: become_a_promoter_name.val(),
                phone: become_a_promoter_telephone.val(),
                email: become_a_promoter_email.val(),
            }
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LdWWfUjAAAAADRniczJganZpLrcafarzJHbSqlz', {action: 'submit'}).then(token => {
                    PostDataRaw('/frontCall/promoters', {
                        ...formData,
                        googleRecaptchaResponse: token
                    })
                        .then(result => {
                            if (result.response === "Email sent successfully.") {
                                become_a_promoter_name.val('')
                                become_a_promoter_telephone.val('')
                                become_a_promoter_email.val('')
                                this.setState({
                                    become_a_promoter_nameErr: "",
                                    become_a_promoter_telephoneErr: "",
                                    become_a_promoter_emailErr: "",
                                    promoterSuccess: {
                                        show: true,
                                        variant: "success",
                                        message: i18next.t("email_sent_successfully"),
                                    }
                                })
                                setTimeout(() => {
                                    this.setState({
                                        promoterSuccess: {
                                            show: false,
                                            variant: "",
                                            message: "",
                                        }
                                    })
                                }, 3000);
                            }
                        })
                        .catch(error => {
                            console.error("Error:", error);
                        });
                });
            });
        }
    }

    componentDidMount() {
        let bodyTag = $("body")
        bodyTag.addClass("light-page").removeClass("dark-page")
        $("#main-sections").attr("style", "padding-top: " + (document.getElementById("sticky-top").offsetHeight + 16) + "px")
        $("#sticky-top").addClass("sticky-top")
        $(".header-bar").removeClass("active-nav").addClass("container")
        $(".burger-menu").removeClass("pointer-opacity")
    }

    becomeAPromoter() {
        if ($("#become_a_promoter-name").val() !== "" && $("#become_a_promoter-telephone").val() !== "" && $("#become_a_promoter-email").val() !== "") {
            $(".become-a-promoter-button").removeAttr("disabled")
        } else if ($("#become_a_promoter-name").val() !== "" || $("#become_a_promoter-telephone").val() !== "" || $("#become_a_promoter-email").val() !== "") {
            $(".become-a-promoter-button").addClass("filling-data").attr("disabled", "disabled")
        } else {
            $(".become-a-promoter-button").addClass("filling-data").attr("disabled", "disabled")
        }
    }

    becomeAPromoter1() {
        if ($("#become_a_promoter-name1").val() !== "" && $("#become_a_promoter-telephone1").val() !== "" && $("#become_a_promoter-email1").val() !== "") {
            $(".become-a-promoter-button").removeAttr("disabled")
        } else if ($("#become_a_promoter-name1").val() !== "" || $("#become_a_promoter-telephone1").val() !== "" || $("#become_a_promoter-email1").val() !== "") {
            $(".become-a-promoter-button").addClass("filling-data").attr("disabled", "disabled")
        } else {
            $(".become-a-promoter-button").removeClass("filling-data").attr("disabled", "disabled")
        }
    }

    render() {
        return (<>
            <Helmet>
                <title>{this.props.pageTitle}</title>
                <meta name="description"
                      content="В связи с активным развитием компании Backpack Media, приглашаем нa работу промоутеров, для показа рекламных кампаний на рюкзаках с FULL HD экранами."/>
            </Helmet>
            <section id="to_the_promoters" className="section promoters-section container">
                <h1 className="about-format-title promoters-title">{i18next.t("promoter_page_h1")}</h1>
                <div className="promoters-section-content">
                    <div className="promoters-content-left">
                        <h6 className="promoters-content-title">
                            {i18next.t("show_advertising_campaigns_on_backpacks_with_full_hd_screens")}
                        </h6>


                        <div className="promoters-content-left-form">
                            <Form className="become-a-promoter-form">
                                <Alert show={this.state.promoterSuccess.show} variant={this.state.promoterSuccess.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.promoterSuccess.message}</h6>
                                </Alert>
                                <div className="become-a-promoter-form-group">
                                    <label htmlFor="become_a_promoter-name1" className="become-a-promoter-form-label">Имя</label>
                                    <input type="text" id="become_a_promoter-name1" name="become_a_promoter-name1"
                                           className="become-a-promoter-form-control"
                                           placeholder={i18next.t("name")} onKeyUp={this.becomeAPromoter1}/>
                                    <span style={{color: "#ED2224"}}>{this.state.become_a_promoter_nameErr}</span>
                                </div>
                                <div className="become-a-promoter-form-group">
                                    <label htmlFor="become_a_promoter-telephone1" className="become-a-promoter-form-label">Телефон</label>
                                    <input type="text" id="become_a_promoter-telephone1" name="become_a_promoter-telephone1"
                                           className="become-a-promoter-form-control"
                                           placeholder={i18next.t("telephone")} onKeyUp={this.becomeAPromoter1}/>
                                    <span style={{color: "#ED2224"}}>{this.state.become_a_promoter_telephoneErr}</span>
                                </div>
                                <div className="become-a-promoter-form-group">
                                    <label htmlFor="become_a_promoter-email1" className="become-a-promoter-form-label">Email</label>
                                    <input type="text" id="become_a_promoter-email1" name="become_a_promoter-email1"
                                           className="become-a-promoter-form-control"
                                           placeholder="Email" onKeyUp={this.becomeAPromoter1}/>
                                    <span style={{color: "#ED2224"}}>{this.state.become_a_promoter_emailErr}</span>
                                </div>
                                <div className="form-button-block">
                                    <button type={"button"} className="become-a-promoter-button" onClick={() => this.handleSubmitPromotersForm("mobile")}>
                                        {i18next.t("become_a_promoter")}
                                    </button>
                                </div>
                                <p className={"text-center mt-3"}>{i18next.t("agree_processing_of_personal_data")} <Link to={"/privacy_policy"} style={{textDecoration: "underline"}}>{i18next.t("privacy_policy")}</Link></p>
                                <GoogleReCaptchaProvider
                                    reCaptchaKey="6LdWWfUjAAAAADRniczJganZpLrcafarzJHbSqlz"
                                    scriptProps={{
                                        async: false, // optional, default to false,
                                        defer: false, // optional, default to false
                                        appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                        nonce: undefined // optional, default undefined
                                    }}
                                ></GoogleReCaptchaProvider>
                            </Form>
                        </div>


                        <h6 className="promoters-content-title">{i18next.t("come_work_with_us_if_you_have")}</h6>
                        <ul className="come-work-with-us-list">
                            <li className="come-work-with-us-list-item">{i18next.t("there_are_free_from_3_hours_a_day")}</li>
                            <li className="come-work-with-us-list-item">{i18next.t("there_is_an_understanding_that_work_is_work")}</li>
                            <li className="come-work-with-us-list-item">{i18next.t("you_are_over_18_years_old")}</li>
                            <li className="come-work-with-us-list-item">{i18next.t("you_are_responsible_and_not_afraid_of_work")}</li>
                        </ul>
                        <h6 className="promoters-content-title">{i18next.t("your_work_is_as_follows")}</h6>
                        <p className="your-work-is-as-follows-detail">{i18next.t("at_the_office")}</p>
                        <p className="your-work-is-as-follows-detail">{i18next.t("through_the_busy_streets_of_moscow")}</p>
                        <ul className="your-work-is-as-follows-list">
                            <li className="your-work-is-as-follows-list-item">{i18next.t("registration_under_the_contract_and_as_self_employed")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("the_schedule_is_free")}</li>
                            <li className="your-work-is-as-follows-list-item">{i18next.t("salary_from_1000_rubles_per_shift")}</li>
                        </ul>
                        <h6 className="promoters-content-title">{i18next.t("why_is_it_good_to_work_with_us")}</h6>
                        <ul className="why-good-to-work-with-us-list">
                            <li className="why-good-to-work-with-us-list-item">{i18next.t("flexible_schedule")}</li>
                            <li className="why-good-to-work-with-us-list-item">{i18next.t("active_lifestyle")}</li>
                            <li className="why-good-to-work-with-us-list-item">{i18next.t("transparent_and_understandable_payment_scheme")}</li>
                            <li className="why-good-to-work-with-us-list-item">{i18next.t("daily_payment")}</li>
                            <li className="why-good-to-work-with-us-list-item">{i18next.t("work_in_all_areas_of_the_city")}</li>
                        </ul>
                    </div>

                    <div className="promoters-content-right">
                        <Form className="become-a-promoter-form">
                            <Alert show={this.state.promoterSuccess.show} variant={this.state.promoterSuccess.variant} className="rounded-lg">
                                <h6 className="m-0 font-weight-bold">{this.state.promoterSuccess.message}</h6>
                            </Alert>
                            <div className="become-a-promoter-form-group">
                                <label htmlFor="become_a_promoter-name" className="become-a-promoter-form-label">{i18next.t("name")}</label>
                                <input type="text" id="become_a_promoter-name" name="become_a_promoter-name" className="become-a-promoter-form-control"
                                       placeholder={i18next.t("name")} onKeyUp={this.becomeAPromoter}/>
                                <span style={{color: "#ED2224"}}>{this.state.become_a_promoter_nameErr}</span>
                            </div>
                            <div className="become-a-promoter-form-group">
                                <label htmlFor="become_a_promoter-telephone" className="become-a-promoter-form-label">{i18next.t("telephone")}</label>
                                <input type="text" id="become_a_promoter-telephone" name="become_a_promoter-telephone"
                                       className="become-a-promoter-form-control"
                                       placeholder={i18next.t("telephone")} onKeyUp={this.becomeAPromoter}/>
                                <span style={{color: "#ED2224"}}>{this.state.become_a_promoter_telephoneErr}</span>
                            </div>
                            <div className="become-a-promoter-form-group">
                                <label htmlFor="become_a_promoter-email" className="become-a-promoter-form-label">Email</label>
                                <input type="text" id="become_a_promoter-email" name="become_a_promoter-email"
                                       className="become-a-promoter-form-control"
                                       placeholder="Email" onKeyUp={this.becomeAPromoter}/>
                                <span style={{color: "#ED2224"}}>{this.state.become_a_promoter_emailErr}</span>
                            </div>
                            <div className="form-button-block">
                                <button type={"button"} className="become-a-promoter-button" onClick={() => this.handleSubmitPromotersForm("desktop")}>
                                    {i18next.t("become_a_promoter")}
                                </button>
                            </div>
                            <p className={"text-center mt-3"}>{i18next.t("agree_processing_of_personal_data")} <Link to={"/privacy_policy"} style={{textDecoration: "underline"}}>{i18next.t("privacy_policy")}</Link></p>
                            <GoogleReCaptchaProvider
                                reCaptchaKey="6LdWWfUjAAAAADRniczJganZpLrcafarzJHbSqlz"
                                scriptProps={{
                                    async: false, // optional, default to false,
                                    defer: false, // optional, default to false
                                    appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                    nonce: undefined // optional, default undefined
                                }}
                            ></GoogleReCaptchaProvider>
                        </Form>
                    </div>
                </div>
            </section>
        </>)
    }
}

export default Promoter;