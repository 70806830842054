import React, {useState} from 'react';
import {Button} from "react-bootstrap";

function Pagination ({pageButtons, totalScreenshots, screenshotsPerPage, currentPageNum, currentPageFun}) {
    let pages = [];

    for (let i = 1; i <= Math.ceil(pageButtons / screenshotsPerPage); i++) {
        pages.push(i);
    }

    return (<>
        <div className="d-flex align-items-center justify-content-between">
            <span>Total Screenshots: {totalScreenshots}</span>
            <ul className="pagination">
                {parseInt(currentPageNum) > 1 && <li className="page-item">
                    <span className="page-link c-pointer"
                          onClick={currentPageFun}
                          data-value={1}
                    ><em className="fa fa-angle-double-left"></em></span>
                </li>}
                {parseInt(currentPageNum) > 2 && <li className="page-item">
                    <span className="page-link c-pointer"
                          onClick={currentPageFun}
                          data-value={parseInt(currentPageNum) - 2}
                    >{parseInt(currentPageNum) - 2}</span>
                </li>}
                {parseInt(currentPageNum) > 1 && <li className="page-item">
                    <span className="page-link c-pointer"
                          onClick={currentPageFun}
                          data-value={parseInt(currentPageNum) - 1}
                    >{parseInt(currentPageNum) - 1}</span>
                </li>}
                <li className="page-item active">
                    <span className="page-link c-pointer"
                          onClick={currentPageNum}
                          data-value={currentPageNum}
                    >{currentPageNum}</span>
                </li>
                {pages.length < parseInt(currentPageNum) + 1 ? "" : <li className="page-item">
                    <span className="page-link c-pointer"
                          onClick={currentPageFun}
                          data-value={parseInt(currentPageNum) + 1}
                    >{parseInt(currentPageNum) + 1}</span>
                </li>}
                {pages.length < parseInt(currentPageNum) + 2 ? "" : <li className="page-item">
                    <span className="page-link c-pointer"
                          onClick={currentPageFun}
                          data-value={parseInt(currentPageNum) + 2}
                    >{parseInt(currentPageNum) + 2}</span>
                </li>}
                {parseInt(currentPageNum) < pages.length && <li className="page-item">
                    <span className="page-link c-pointer"
                          onClick={currentPageFun}
                          data-value={pages.length}
                    ><em className="fa fa-angle-double-right"></em></span>
                </li>}
            </ul>
        </div>
    </>)
}

export default Pagination;