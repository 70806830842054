import React from 'react';
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Pagination from "../../Common/Pagination/Pagination";
import {Alert, Button, Card, Col, Form, Modal, Row, Spinner, Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import {PostData} from "../../api_key/PostData";
import DataTable from 'react-data-table-component';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import i18next from "i18next";
import moment from "moment";
import ProgressBar from "react-bootstrap/ProgressBar";

class Devices extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_devices"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions: currentPermission.granular_types,
            permissionKeysList: permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            addNewDeviceUse: false,
            handleDeletePopup: false,
            editDeviceForm: false,
            deviceName: '',
            deviceNameErr: '',
            newDeviceOpeAlert: false,
            newDeviceOpeAlertVariant: '',
            newDeviceOpeAlertMessage: '',
            commonAlert: false,
            commonAlertVariant: '',
            commonAlertMessage: '',
            connectionCode: '',
            devicesList: [],
            columns: [],
            deviceOptions: [
                {value: "1", label: i18next.t("android_screen")},
                {value: "2", label: i18next.t("android_phone")},
            ],
            uuid: null,
            selectedOption: '',
            screenShotModal: false,
            screenShotIntervalModal: false,
            screenShotInterval: '',
            screenShotIntervalErr: '',
            showScreenshotList: '',
            screenshotList: [],
            startDate: null,
            deleteScreenShotOpeAlert: false,
            deleteScreenShotOpeAlertVariant: '',
            deleteScreenShotOpeAlertMessage: '',
            optionsValue: [],
            optionsValueErr: '',
            imageGalleryOpen: false,
            galleryImagePath: '',
            downloadReportModal: false,
            downloadReportStartDate: null,
            downloadReportEndDate: null,
            deleteMultipleStatus: false,
            deleteMultipleScreenShotModal: false,
            deleteMultipleScreenshotUuid: [],
            assignPromoterRoute: false,
            dataLoader: true,
            assignCampaign: false,
            reportDownloadProgress: false,
            appControlsModal: false,
            appControlsAlert: false,
            appControlsAlertVariant: "",
            appControlsAlertMessage: "",
            cityOptions: [],
            cityOptionsErr: "",
            cityOptionSelected: [],
            inlinePreloader: false,
            screenshotListData: [],
            screenshotPerPage: 10,
            currentPageNum: 1,
            deviceControlsModal: false,
            partnersOptionSelected: [],
        }
        document.title = this.props.pageTitle;
        this.handleClose = this.handleClose.bind(this)
        this.submitDevicesForm = this.submitDevicesForm.bind(this)
        this.handleDeleteDevice = this.handleDeleteDevice.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.downloadScreenShot = this.downloadScreenShot.bind(this)
        this.saveScreenshotTime = this.saveScreenshotTime.bind(this)
        this.loadAllScreenshots = this.loadAllScreenshots.bind(this)
        this.loadGalleryNextButton = this.loadGalleryNextButton.bind(this)
        this.deleteMultipleScreenshots = this.deleteMultipleScreenshots.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
        this.loadCampaignOptions = this.loadCampaignOptions.bind(this)
        this.loadPromoterOptions = this.loadPromoterOptions.bind(this)
        this.onAssignCampaign = this.onAssignCampaign.bind(this)
        this.onUnAssignCampaign = this.onUnAssignCampaign.bind(this)
        this.onAssignPromoterRoute = this.onAssignPromoterRoute.bind(this)
        this.appControls = this.appControls.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.loadScreenshotHtml = this.loadScreenshotHtml.bind(this)
    }

    downloadReport(startDate, endDate) {
        this.setState({reportDownloadProgress: true})
        let formData = {
            device_uuid: this.state.uuid,
            start_date: startDate,
            end_date: endDate
        };
        PostData("/frontCall/devices/downloadLocationReport", formData)
            .then((result) => {
                let commonAlertVariant = "", commonAlertMessage = ""
                if (result.status) {
                    commonAlertVariant = "success"
                    commonAlertMessage = i18next.t("download_success_device")
                } else {
                    commonAlertVariant = "danger"
                    commonAlertMessage = i18next.t("download_report_err")
                }
                this.setState({
                    downloadReportModal: false,
                    commonAlert: true,
                    commonAlertVariant: commonAlertVariant,
                    commonAlertMessage: commonAlertMessage,
                    reportDownloadProgress: false,
                })
                setTimeout(() => this.handleClose(), 5000);
            })
    }

    deleteMultipleScreenshots() {
        PostData("/frontCall/devices/delete_multiple_screenshots", {screenshot_uuids: this.state.deleteMultipleScreenshotUuid})
            .then((result) => {
                if (result.status === true) {
                    this.setState({
                        deleteScreenShotOpeAlert: true,
                        deleteScreenShotOpeAlertVariant: "success",
                        deleteScreenShotOpeAlertMessage: i18next.t("deleted_successful"),
                        deleteMultipleScreenShotModal: false,
                        deleteMultipleScreenshotUuid: [],
                    })
                    this.loadAllScreenshots(this.state.uuid)
                } else if (result.messages.message === "The Screen Shot uuid is required.") {
                    this.setState({
                        deleteScreenShotOpeAlert: true,
                        deleteScreenShotOpeAlertVariant: "danger",
                        deleteScreenShotOpeAlertMessage: i18next.t("the_screen_shot_uuid_is_required"),
                        deleteMultipleScreenShotModal: false,
                    })
                } else {
                    this.setState({
                        deleteScreenShotOpeAlert: true,
                        deleteScreenShotOpeAlertVariant: "danger",
                        deleteScreenShotOpeAlertMessage: result.messages.message,
                        deleteMultipleScreenShotModal: false,
                    })
                }
            })
    }

    loadGalleryNextButton(arrayPosition) {
        for (let index = 0; index < this.state.screenshotList.length; index++) {
            if (arrayPosition === 0) {
                document.querySelector(".screenshot-image-gallery-prev-btn").classList.add("d-none")
            } else {
                document.querySelector(".screenshot-image-gallery-prev-btn").classList.remove("d-none")
                this.setState({prevScreenshotImage: this.state.screenshotList[arrayPosition - 1].file_name})
            }
            if (arrayPosition > -1 && index === arrayPosition && arrayPosition < this.state.screenshotList.length) {
                this.setState({galleryImagePath: this.state.screenshotList[arrayPosition].file_name})
            }
            if (arrayPosition === (this.state.screenshotList.length - 1)) {
                document.querySelector(".screenshot-image-gallery-next-btn").classList.add("d-none")
            } else {
                document.querySelector(".screenshot-image-gallery-next-btn").classList.remove("d-none")
                this.setState({nextScreenshotImage: this.state.screenshotList[arrayPosition + 1].file_name})
            }
        }
    }

    loadAllScreenshots(deviceUuid, filterScreenshot) {
        let formData = {
            device_uuid: deviceUuid,
        }
        if (filterScreenshot) {
            formData.date = filterScreenshot;
        }
        this.setState({inlinePreloader: true})
        PostData("/frontCall/devices/get_screenshots", formData)
            .then((result) => {
                this.setState({
                    optionsValueErr: (result.response < 1) ? i18next.t("screenshot_not_found") : "",
                    screenshotListData: result.response,
                })
                this.loadScreenshotHtml(result.response, 1)
            })
        this.setState({inlinePreloader: false})
    }

    loadScreenshotHtml(resultArray, currentPageNum) {
        let deleteMultipleScreenshotUuid = []
        const lastPostIndex = currentPageNum * this.state.screenshotPerPage;
        const firstPostIndex = lastPostIndex - this.state.screenshotPerPage;
        const currentPosts = resultArray.slice(firstPostIndex, lastPostIndex);
        let screen_shot_list = currentPosts.map((data, index) =>
            (<div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={data.uuid}>
                <a href={data.file_name} className="screenshot-image-list" onClick={(event) => {
                    event.preventDefault()
                    if (index === 0) {
                        document.querySelector(".screenshot-image-gallery-prev-btn").classList.add("d-none")
                    } else {
                        document.querySelector(".screenshot-image-gallery-prev-btn").classList.remove("d-none")
                        this.setState({prevScreenshotImage: resultArray[index - 1].file_name})
                    }
                    this.setState({
                        imageGalleryOpen: true,
                        galleryImagePath: data.file_name,
                        arrayPosition: index,
                    })
                    if (index === (resultArray.length - 1)) {
                        document.querySelector(".screenshot-image-gallery-next-btn").classList.add("d-none")
                    } else {
                        document.querySelector(".screenshot-image-gallery-next-btn").classList.remove("d-none")
                        this.setState({nextScreenshotImage: resultArray[index + 1].file_name})
                    }
                }}>
                    <img src={data.file_name} className="screenshot-image rounded" alt=""/>
                </a>
                {this.state.granularPermissions.includes('d') &&
                    <Form.Check
                        className={"custom-control custom-checkbox d-none screen-shot-multiple-delete-check"}>
                        <Form.Control type={"checkbox"} className={"custom-control-input"} id={data.uuid}
                                      value={data.uuid}
                                      onChange={() => {
                                          const uuidFound = deleteMultipleScreenshotUuid.indexOf(data.uuid)
                                          if (uuidFound > -1) {
                                              deleteMultipleScreenshotUuid.splice(uuidFound, 1);
                                          } else {
                                              deleteMultipleScreenshotUuid.push(data.uuid)
                                          }
                                          this.setState({
                                              deleteMultipleScreenshotUuid: deleteMultipleScreenshotUuid
                                          })
                                      }}
                        />
                        <Form.Label className={"custom-control-label"} htmlFor={data.uuid}></Form.Label>
                    </Form.Check>}
                {(this.state.granularPermissions.includes('d')) &&
                    <Button className="screen-shot-delete-btn px-2 py-1" variant={"danger"} onClick={() => {
                        this.setState({
                            deleteScreenShotModal: true,
                            deleteScreenShotUuid: data.uuid,
                        })
                    }}><em className="fa fa-trash"></em></Button>}
                <Button className="screen-shot-download-btn px-2 py-1" variant={"primary"} onClick={() => {
                    let a = document.createElement("a");
                    a.href = data.file_name;
                    a.target = "_blank";
                    a.click();
                }}><em className="fa fa-download"></em></Button>
                <span className="screenshot-taken-date">{data.date}</span>
            </div>)
        )
        this.setState({showScreenshotList: screen_shot_list, screenshotList: currentPosts})
    }

    handlePageChange(e) {
        this.setState({currentPageNum: e.target.dataset.value})
        this.loadScreenshotHtml(this.state.screenshotListData, e.target.dataset.value)
    }

    saveScreenshotTime() {
        const form = document.getElementById('screenshot_settings');
        let formData = new FormData(form);
        let apiFormData = []
        formData.forEach((value, key) => {
            apiFormData.push({"setting_uuid": key, "setting_value": value})
        })
        const formDataToSubmit = {
            device_uuid: this.state.uuid,
            setting_data: apiFormData
        }
        PostData("/frontCall/devices/save_settings", formDataToSubmit)
            .then((result) => {
                if (result.status === true) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("time_interval_added"),
                    })
                    this.loadAllDevices()
                    this.handleClose()
                } else {
                    this.setState({
                        screenShotIntervalErr: i18next.t("enter_time_interval"),
                    })
                }
            })
    }

    appControls(controlType, controlName) {
        this.setState({
            appControlsAlert: false,
            appControlsAlertVariant: "",
            appControlsAlertMessage: "",
        })
        const formDataToSubmit = {
            uuid: this.state.uuid,
            control_type: controlType
        }
        let successMessage = controlName === 0 ? i18next.t("app_control_sent") : i18next.t("device_control_sent")
        PostData("/frontCall/devices/appControls", formDataToSubmit)
            .then((result) => {
                if (result.status === true) {
                    this.setState({
                        appControlsAlert: true,
                        appControlsAlertVariant: "success",
                        appControlsAlertMessage: successMessage,
                    })
                }
            })
    }

    handleClose() {
        this.setState({
            addNewDeviceUse: false,
            deviceName: '',
            selectedOption: '',
            handleDeletePopup: false,
            editDeviceForm: false,
            deviceNameErr: '',
            selectedOptionErr: '',
            newDeviceOpeAlert: false,
            newDeviceOpeAlertVariant: '',
            newDeviceOpeAlertMessage: '',
            screenShotModal: false,
            screenShotIntervalModal: false,
            screenShotInterval: '',
            screenShotIntervalErr: '',
            screenshotList: '',
            showScreenshotList: '',
            startDate: null,
            commonAlert: false,
            commonAlertVariant: '',
            commonAlertMessage: '',
            deleteScreenShotOpeAlert: false,
            deleteScreenShotOpeAlertVariant: '',
            deleteScreenShotOpeAlertMessage: '',
            optionsValueErr: '',
            downloadReportModal: false,
            downloadReportStartDate: null,
            downloadReportEndDate: null,
            deleteMultipleStatus: false,
            deleteMultipleScreenShotModal: false,
            deleteMultipleScreenshotUuid: [],
            assignPromoterRoute: false,
            assignCampaign: false,
            uuid: "",
            reportDownloadProgress: false,
            appControlsModal: false,
            appControlsAlert: false,
            appControlsAlertVariant: "",
            appControlsAlertMessage: "",
            cityOptionSelected: [],
            cityOptionsErr: "",
            inlinePreloader: false,
            screenshotListData: [],
            currentPageNum: 1,
            deviceControlsModal: false,
            partnersOptionSelected: [],
        })
    }

    componentDidMount() {
        this.loadAllDevices();
        if (this.state.granularPermissions.includes('a')) {
            this.loadCampaignOptions();
            this.loadPromoterOptions();
        }
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    loadCampaignOptions() {
        PostData("/frontCall/campaigns/get_all_campaigns")
            .then((result) => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    let campaignOptions = result.response.map(x => ({'value': x.uuid, 'label': x.title}));
                    this.setState({
                        campaignOptions: campaignOptions
                    })
                }
            })
    }

    onAssignCampaign() {
        if (this.state.selectedOption === "") {
            this.setState({selectedOptionErr: i18next.t("select_campaign")})
            return
        }
        this.setState({selectedOptionErr: ""})
        PostData("/frontCall/campaigns/assignCampaigns", {
            device_uuid: this.state.uuid,
            campaign_uuid: this.state.selectedOption.value
        })
            .then((result) => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.handleClose()
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("campaign_assigned_successfully"),
                    })
                    this.loadAllDevices()
                }
            })
    }

    onUnAssignCampaign() {
        PostData("/frontCall/campaigns/unAssignCampaigns", {
            device_uuid: this.state.uuid,
        })
            .then((result) => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.handleClose()
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("campaign_removed_successfully"),
                    })
                    this.loadAllDevices()
                }
            })
    }

    loadPromoterOptions() {
        PostData("/frontCall/promoter_routes/get_all_promoter_routes")
            .then((result) => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    let routeOptions = result.response.map(x => ({'value': x.uuid, 'label': x.title}));
                    this.setState({
                        promoterRouteOptions: routeOptions
                    })
                }
            })
    }

    onAssignPromoterRoute(assignValue) {
        if (this.state.selectedOption === "") {
            this.setState({selectedOptionErr: i18next.t("select_promoter_route")})
            return
        }
        this.setState({selectedOptionErr: ""})

        let postApi = "", commonAlertMessage = ""
        if (assignValue) {
            postApi = "/frontCall/devices/assignPromoterRoute"
            commonAlertMessage = i18next.t("promoter_route_assigned_successfully")
        } else {
            postApi = "/frontCall/devices/unAssignPromoterRoute"
            commonAlertMessage = i18next.t("promoter_route_unassigned_successfully")
        }
        let formData = {
            device_uuid: this.state.uuid,
            promoter_route_uuid: this.state.selectedOption.value
        }

        PostData(postApi, formData)
            .then((result) => {
                if (result.status === 400) {
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "danger",
                        commonAlertMessage: result.messages.message,
                    })
                } else {
                    this.handleClose()
                    this.setState({
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: commonAlertMessage,
                    })
                    this.loadAllDevices()
                    setTimeout(() => this.handleClose(), 5000);
                }
            })
    }

    loadAllDevices() {
        // Load All Cities
        PostData("/frontCall/cities/getAll")
            .then(result => {
                let cityOptions = []
                result.response.map(data => {
                    cityOptions.push({label: data.name, value: data.uuid})
                })
                this.setState({cityOptions: cityOptions})
            })
        if (this.state.permissionKeysList.includes('manage_partners')) {
            // Load All Partners
            PostData("/frontCall/partners/getAllPartners")
                .then(result => {
                    let partnerOptions = []
                    result.response.map(data => partnerOptions.push({label: data.full_name, value: data.uuid}))
                    this.setState({partnerOptions: partnerOptions})
                })
        }
        PostData('/frontCall/devices/getAll')
            .then(result => {
                this.setState({
                    devicesList: result.response,
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("title")}</h6>,
                            selector: (row) => row.device_name,
                            sortable: true,
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("status")}</h6>,
                            selector: (row) => (row.status === "Offline") ?
                                <Badge bg="danger" className={"text-white"}>{i18next.t("offline")}</Badge> :
                                <Badge bg="success" className={"text-white"}>{i18next.t("online")}</Badge>,
                            sortable: true
                        },
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("setting")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                let settingDeviceValue = row.settings.map((data, index) =>
                                    (<Form.Group key={index} className="mb-3" controlId={"setting_uuid_" + index}>
                                        <Form.Label>{data.title}</Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control type="text" name={data.uuid}
                                                          defaultValue={data.setting_value}
                                                          placeholder={i18next.t("enter_value")}/>
                                            <OverlayTrigger overlay={<Tooltip>{data.description}</Tooltip>}>
                                                <Badge bg="primary" className={"text-white ml-2"}>
                                                    <em className="flaticon-381-warning-1"></em>
                                                </Badge>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>))
                                this.setState({
                                    screenShotIntervalModal: true,
                                    screenShotInterval: settingDeviceValue,
                                    uuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-settings-6"/>
                            </Button>
                        }] : [],
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("assign_route")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                let selectedPromoterRoute = null;
                                if (row.promoter_route) {
                                    selectedPromoterRoute = [{
                                        value: row.promoter_route.uuid,
                                        label: row.promoter_route.title
                                    }];
                                }
                                this.setState({
                                    uuid: row.uuid,
                                    assignPromoterRoute: true,
                                    selectedOption: selectedPromoterRoute ?? ""
                                })
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20"
                                     viewBox="0 0 24 24">
                                    <path
                                        d="M3,4V12.5L6,9.5L9,13C10,14 10,15 10,15V21H14V14C14,14 14,13 13.47,12C12.94,11 12,10 12,10L9,6.58L11.5,4M18,4L13.54,8.47L14,9C14,9 14.93,10 15.47,11C15.68,11.4 15.8,11.79 15.87,12.13L21,7"
                                        fill={"#fff"}
                                    />
                                </svg>
                            </Button>
                        }] : [],
                        ...(this.state.granularPermissions.includes('a')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("ad_campaigns")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                let selectedCampaign = null;
                                if (row.campaign) {
                                    selectedCampaign = [{value: row.campaign.uuid, label: row.campaign.title}];
                                }
                                this.setState({
                                    uuid: row.uuid,
                                    assignCampaign: true,
                                    selectedOption: selectedCampaign ?? ""
                                })
                            }}>
                                <em className="flaticon-381-promotion"/>
                            </Button>
                        }] : [],
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("screenshot")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    uuid: row.uuid,
                                    screenshotUuid: row.uuid,
                                    screenShotModal: true,
                                })
                                this.loadAllScreenshots(row.uuid)
                            }}>
                                <em className="flaticon-381-picture"/>
                            </Button>
                        },
                        {
                            name: <h6 className="font-weight-bold">{i18next.t("download_report")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                this.setState({
                                    commonAlert: false,
                                    commonAlertVariant: "",
                                    commonAlertMessage: "",
                                    deleteScreenShotOpeAlert: false,
                                    deleteScreenShotOpeAlertVariant: "",
                                    deleteScreenShotOpeAlertMessage: "",
                                    downloadReportModal: true,
                                    uuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-list-1"/>
                            </Button>
                        },
                        ...(this.state.granularPermissions.includes('u')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                            cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                let partnersOptionSelected = []
                                row.partners.map(item => partnersOptionSelected.push({
                                    label: item.full_name,
                                    value: item.uuid
                                }))
                                this.setState({
                                    addNewDeviceUse: true,
                                    deviceName: row.device_name,
                                    editDeviceForm: true,
                                    uuid: row.uuid,
                                    selectedOption: [
                                        {
                                            value: row.device_type,
                                            label: (parseInt(row.device_type) === 1 ? i18next.t("android_screen")
                                                : parseInt(row.device_type) === 2 ? i18next.t("android_phone")
                                                    : "Choose Correct")
                                        },
                                    ],
                                    cityOptionSelected: {label: row.city.name, value: row.city.uuid},
                                    partnersOptionSelected: partnersOptionSelected
                                })
                            }}>
                                <em className="flaticon-381-edit"/>
                            </Button>
                        }] : [],
                        ...(this.state.granularPermissions.includes('d')) ? [{
                            name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                            cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                this.setState({
                                    handleDeletePopup: true,
                                    uuid: row.uuid,
                                })
                            }}>
                                <em className="flaticon-381-trash-1"/>
                            </Button>
                        }] : [],
                    ],
                })
            })
    }

    submitDevicesForm() {
        if (this.state.deviceName === "" || Object.keys(this.state.cityOptionSelected).length === 0 || this.state.selectedOption === "") {
            if (this.state.deviceName === "") {
                this.setState({deviceNameErr: i18next.t("device_name_err")})
            } else {
                this.setState({deviceNameErr: ""})
            }
            if (this.state.selectedOption === "") {
                this.setState({selectedOptionErr: i18next.t("select_device")})
            } else {
                this.setState({selectedOptionErr: ""})
            }
            if (Object.keys(this.state.cityOptionSelected).length === 0) {
                this.setState({cityOptionsErr: i18next.t("select_city")})
            } else {
                this.setState({cityOptionsErr: ""})
            }
        } else {
            this.setState({
                inlinePreloader: true,
                deviceNameErr: "",
                selectedOptionErr: "",
                cityOptionsErr: "",
                partnerOptionsErr: "",
            })
            let partners_id = []
            this.state.partnersOptionSelected.map((data, index) => {
                partners_id[index] = data.value
            })
            let deviceData = {
                device_name: this.state.deviceName,
                device_type: this.state.selectedOption.value,
                city_id: this.state.cityOptionSelected.value,
                partner_uuids: partners_id,
                activation_code: this.state.connectionCode
            }
            if (this.state.editDeviceForm) {
                deviceData.uuid = this.state.uuid;
                delete deviceData.activation_code
            }
            let postApi = this.state.editDeviceForm ? "/frontCall/devices/updateDevice" : "/frontCall/devices/add";
            PostData(postApi, deviceData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            newDeviceOpeAlert: true,
                            newDeviceOpeAlertVariant: "success",
                            newDeviceOpeAlertMessage: this.state.editDeviceForm ? i18next.t("device_updated") : i18next.t("device_added"),
                            inlinePreloader: false,
                        })
                        setTimeout(() => {
                            this.loadAllDevices()
                            this.handleClose()
                        }, 2000)
                    } else {
                        this.setState({
                            newDeviceOpeAlert: true,
                            newDeviceOpeAlertVariant: "danger",
                            newDeviceOpeAlertMessage: result.messages.message,
                            inlinePreloader: false,
                        })
                    }
                })
        }
    }

    handleDeleteDevice() {
        PostData("/frontCall/devices/deleteDevice", {uuid: this.state.uuid})
            .then(result => {
                if (result.status) {
                    this.setState({
                        handleDeletePopup: false,
                        commonAlert: true,
                        commonAlertVariant: "success",
                        commonAlertMessage: i18next.t("device_removed"),
                    })
                }
            })
        this.loadAllDevices()
        setTimeout(() => this.handleClose(), 5000);
    }

    downloadScreenShot(filePath) {
        let a = document.createElement("a");
        a.href = filePath;
        a.target = "_blank";
        a.setAttribute("download", filePath.split("/").pop());
        a.click();
    }

    handleChange = (selectedOption) => {
        this.setState({selectedOption}, () =>
            this.state.selectedOption
        );
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {this.state.granularPermissions.includes('c') &&
                            <div className="text-right mb-3">
                                <Button variant={"primary"} className="mb-2" onClick={() => {
                                    PostData("/frontCall/devices/generateActivationCode")
                                        .then(result => {
                                            this.setState({connectionCode: result.response})
                                        })
                                    this.setState({addNewDeviceUse: true})
                                }}>{i18next.t("add_new_device")}</Button>
                            </div>
                        }
                        <div className="screenshot-image-gallery mb-3"
                             style={{display: this.state.imageGalleryOpen ? "flex" : "none"}}>
                            <span className="screenshot-image-gallery-close flaticon-381-multiply-1" onClick={() =>
                                this.setState({imageGalleryOpen: false, galleryImagePath: ""})
                            }></span>
                            <span className="screenshot-image-gallery-next-btn fa fa-arrow-right" onClick={() => {
                                this.setState({arrayPosition: this.state.arrayPosition + 1})
                                this.loadGalleryNextButton(this.state.arrayPosition + 1)
                            }}></span>
                            <span className="screenshot-image-gallery-prev-btn fa fa-arrow-left" onClick={() => {
                                this.setState({arrayPosition: this.state.arrayPosition - 1})
                                this.loadGalleryNextButton(this.state.arrayPosition - 1)
                            }}></span>
                            <img src={this.state.galleryImagePath} alt=""
                                 className="screenshot-image-gallery-image"/>
                        </div>
                        <Modal show={this.state.addNewDeviceUse} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editDeviceForm ? i18next.t("edit") : i18next.t("add")} {i18next.t("device")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.inlinePreloader ?
                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                    <Alert show={this.state.newDeviceOpeAlert}
                                           variant={this.state.newDeviceOpeAlertVariant} className="rounded-lg">
                                        <h6 className="m-0 font-weight-bold">{this.state.newDeviceOpeAlertMessage}</h6>
                                    </Alert>}
                                <Form>
                                    <Form.Group className="mb-3" controlId="deviceName">
                                        <Form.Label>{i18next.t("device_name")}</Form.Label>
                                        <Form.Control type="text" name="deviceName"
                                                      value={this.state.deviceName}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("name")}/>
                                        {this.state.deviceNameErr &&
                                            <span
                                                className="text-danger pl-3 pt-1">{this.state.deviceNameErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="deviceType">
                                        <Form.Label>{i18next.t("device_type")}</Form.Label>
                                        <Select value={this.state.selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.deviceOptions}
                                        />
                                        {this.state.selectedOptionErr &&
                                            <span
                                                className="text-danger pl-3 pt-1">{this.state.selectedOptionErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="selectCity">
                                        <Form.Label>{i18next.t("select_city")}</Form.Label>
                                        <Select value={this.state.cityOptionSelected}
                                                onChange={(cityOptionSelected) => {
                                                    this.setState({cityOptionSelected}, () =>
                                                        this.state.cityOptionSelected
                                                    );
                                                }}
                                                options={this.state.cityOptions}
                                        />
                                        {this.state.cityOptionsErr &&
                                            <span className="text-danger pl-3 pt-1">{this.state.cityOptionsErr}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="selectPartners">
                                        <Form.Label>{i18next.t("select_partners") + " (" + i18next.t("optional")})</Form.Label>
                                        <Select value={this.state.partnersOptionSelected} isMulti={true}
                                                onChange={(partnersOptionSelected) => {
                                                    this.setState({partnersOptionSelected}, () =>
                                                        this.state.partnersOptionSelected
                                                    );
                                                }}
                                                options={this.state.partnerOptions}
                                        />
                                    </Form.Group>
                                    {!this.state.editDeviceForm &&
                                        <div className="text-center mb-3">
                                            <p className="mb-2">{i18next.t("connection_code")}</p>
                                            <h5 className="bg-light m-0 p-3">{this.state.connectionCode}</h5>
                                        </div>
                                    }
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"} onClick={this.submitDevicesForm}
                                        disabled={this.state.inlinePreloader}>
                                    {this.state.inlinePreloader ?
                                        <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                        : i18next.t("save_changes")
                                    }
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("remove_device_modal")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"}
                                        onClick={this.handleDeleteDevice}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.screenShotIntervalModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("settings")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Header className={"justify-content-between"}>
                                <Button variant={"primary"} onClick={() => {
                                    this.setState({appControlsModal: true})
                                }}><em className="flaticon-381-controls-3"></em> {i18next.t("app_controls")}</Button>
                                <Button variant={"primary"} onClick={() => {
                                    this.setState({deviceControlsModal: true})
                                }}><em className="flaticon-381-smartphone-3"></em> {i18next.t("device_controls")}
                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <Form id={"screenshot_settings"}>
                                    {this.state.screenShotInterval}
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"primary"} onClick={this.saveScreenshotTime}>
                                    {i18next.t("save")}
                                </Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.appControlsModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("app_controls")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.appControlsAlert} variant={this.state.appControlsAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.appControlsAlertMessage}</h6>
                                </Alert>
                                <div className="d-flex flex-wrap">
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"primary"} className={"w-100"} onClick={() => {
                                            this.appControls("start", 0);
                                        }}>{i18next.t("start_app")}</Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"danger"} className={"w-100"} onClick={() => {
                                            this.appControls("stop", 0);
                                        }}>{i18next.t("kill_app")}</Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"primary"} className={"w-100"} onClick={() => {
                                            this.appControls("campaign_start", 0);
                                        }}>{i18next.t("start_campaign")}</Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"danger"} className={"w-100"} onClick={() => {
                                            this.appControls("campaign_stop", 0);
                                        }}>{i18next.t("stop_campaign")}</Button>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={() => {
                                    this.setState({appControlsModal: false, appControlsAlert: false})
                                }}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.deviceControlsModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("device_controls")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.appControlsAlert} variant={this.state.appControlsAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.appControlsAlertMessage}</h6>
                                </Alert>
                                <div className="d-flex flex-wrap">
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"primary"} className={"w-100"} onClick={() => {
                                            this.appControls("screen_on", 1);
                                        }}>{i18next.t("screen_on")}</Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"danger"} className={"w-100"} onClick={() => {
                                            this.appControls("screen_off", 1);
                                        }}>{i18next.t("screen_off")}</Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"primary"} className={"w-100"} onClick={() => {
                                            this.appControls("volume_mute", 1);
                                        }}><em class="flaticon-381-mute"></em> {i18next.t("mute")}</Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"danger"} className={"w-100"} onClick={() => {
                                            this.appControls("volume_full", 1);
                                        }}><em class="flaticon-381-high-volume"></em> {i18next.t("full_volume")}
                                        </Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"danger"} className={"w-100"} onClick={() => {
                                            this.appControls("upload_logs", 1);
                                        }}><em class="flaticon-381-file"></em> {i18next.t("upload_logs")}
                                        </Button>
                                    </div>
                                    <div className={"w-50 p-1"}>
                                        <Button variant={"danger"} className={"w-100"} onClick={() => {
                                            this.appControls("upload_db", 1);
                                        }}><em class="flaticon-381-database"></em> {i18next.t("upload_db")}
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={() => {
                                    this.setState({deviceControlsModal: false, appControlsAlert: false})
                                }}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.screenShotModal} onHide={this.handleClose} size="xl" centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("screenshot")}</Modal.Title>
                                <Button variant={"white"} className="py-0 px-2"
                                        onClick={this.handleClose}>&times;</Button>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="deviceType">
                                        <Form.Label>{i18next.t("choose_date_to_filter")}</Form.Label>
                                        <DatePicker className="form-control"
                                                    selected={this.state.startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText={'dd/mm/yyyy'}
                                                    onChange={(date) => {
                                                        this.setState({startDate: date})
                                                        this.loadAllScreenshots(this.state.uuid, moment(date).format("YYYY-MM-DD"))
                                                    }}/>
                                    </Form.Group>
                                </Form>
                                <Alert show={this.state.deleteScreenShotOpeAlert}
                                       variant={this.state.deleteScreenShotOpeAlertVariant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.deleteScreenShotOpeAlertMessage}</h6>
                                </Alert>
                                {this.state.optionsValueErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.optionsValueErr}</span>}
                                {this.state.inlinePreloader ?
                                    <Spinner animation="grow" variant="info" className={"my-5 d-block mx-auto"}/> :
                                    <div className="row">{this.state.showScreenshotList}</div>}
                                <Pagination
                                    pageButtons={this.state.screenshotListData.length}
                                    totalScreenshots={this.state.screenshotListData.length}
                                    screenshotsPerPage={this.state.screenshotPerPage}
                                    currentPageNum={this.state.currentPageNum}
                                    currentPageFun={this.handlePageChange}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                {(this.state.granularPermissions.includes('d') && !this.state.deleteMultipleStatus) &&
                                    <Button variant={"primary"} onClick={() => {
                                        this.setState({deleteMultipleStatus: true})
                                        document.querySelectorAll(".screen-shot-multiple-delete-check").forEach((item) => item.classList.remove("d-none"))
                                    }}>
                                        {i18next.t("delete_multiple_screenshots")}
                                    </Button>}
                                {((this.state.granularPermissions.includes('d')) && this.state.deleteMultipleStatus) && <>
                                    <Button variant={"primary"}
                                            onClick={() => this.setState({deleteMultipleScreenShotModal: true})}>
                                        {i18next.t("delete_selected")}
                                    </Button>
                                    <Button variant={"danger"} onClick={() => {
                                        this.setState({deleteMultipleStatus: false})
                                        document.querySelectorAll(".screen-shot-multiple-delete-check").forEach((item) => item.classList.add("d-none"))
                                    }}>
                                        {i18next.t("cancel_multiple_delete")}
                                    </Button>
                                </>}
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.deleteScreenShotModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("delete_screenshot")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} onClick={() => {
                                    PostData("/frontCall/devices/delete_screenshot", {screenshot_uuid: this.state.deleteScreenShotUuid})
                                        .then(() => {
                                            this.setState({
                                                deleteScreenShotOpeAlert: true,
                                                deleteScreenShotOpeAlertVariant: "success",
                                                deleteScreenShotOpeAlertMessage: i18next.t("deleted_successful"),
                                                deleteScreenShotModal: false,
                                                deleteScreenShotUuid: '',
                                            })
                                            this.loadAllScreenshots(this.state.uuid)
                                        })
                                }}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"}
                                        onClick={() => this.setState({deleteScreenShotModal: false})}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.deleteMultipleScreenShotModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center flex-wrap">
                                <Modal.Title>{this.state.deleteMultipleScreenshotUuid.length} {i18next.t("screenshots_selected")}</Modal.Title>
                                <Modal.Title>{i18next.t("are_you_sure_want_to_delete_all_screenshots")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"}
                                        onClick={this.deleteMultipleScreenshots}>{i18next.t("yes")}</Button>
                                <Button variant={"danger"}
                                        onClick={() => this.setState({deleteMultipleScreenShotModal: false})}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.downloadReportModal} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>{i18next.t("download_report")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.deleteScreenShotOpeAlert}
                                       variant={this.state.deleteScreenShotOpeAlertVariant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.deleteScreenShotOpeAlertMessage}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group controlId="deviceType">
                                        <Form.Label>{i18next.t("choose_date_to_download_report")}</Form.Label>
                                        <Row>
                                            <Col>
                                                <DatePicker className="form-control"
                                                            selected={this.state.downloadReportStartDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText={'dd/mm/yyyy'}
                                                            onChange={(date) => {
                                                                this.setState({downloadReportStartDate: date})
                                                            }}/>
                                            </Col>
                                            <Col>
                                                <DatePicker className="form-control"
                                                            selected={this.state.downloadReportEndDate}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText={'dd/mm/yyyy'}
                                                            minDate={this.state.downloadReportStartDate}
                                                            onChange={(date) => {
                                                                this.setState({downloadReportEndDate: date})
                                                            }}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"primary"} onClick={() => {
                                    this.downloadReport(moment(this.state.downloadReportStartDate).format("YYYY-MM-DD"), moment(this.state.downloadReportEndDate).format("YYYY-MM-DD"))
                                }}>{i18next.t("download_report")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.reportDownloadProgress} centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center">
                                <Modal.Title>{i18next.t("please_wait_generating_report")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ProgressBar style={{height: 30, fontSize: 15}} animated
                                             label={i18next.t("generating_report")} now={100}/>
                            </Modal.Body>
                        </Modal>
                        <Modal show={this.state.assignPromoterRoute} onHide={this.handleClose} centered
                               backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{i18next.t("assign_route")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                </Alert>

                                <Select isSearchable={true}
                                        value={this.state.selectedOption}
                                        onChange={this.handleChange}
                                        options={this.state.promoterRouteOptions}
                                />
                                {this.state.selectedOptionErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.selectedOptionErr}</span>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                {this.state.selectedOption && (
                                    <Button variant={"danger"} onClick={() => this.onAssignPromoterRoute(0)}>
                                        {i18next.t("unassign")}
                                    </Button>
                                )}
                                <Button variant={"primary"}
                                        onClick={() => this.onAssignPromoterRoute(1)}>{i18next.t("assign")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.assignCampaign} onHide={this.handleClose} centered backdrop="static"
                               keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{i18next.t("assign_campaign")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                       className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                </Alert>

                                <p>{i18next.t("campaign_to_device_unavailable_bookings")}</p>

                                <div></div>

                                <Select isSearchable={true}
                                        value={this.state.selectedOption}
                                        onChange={this.handleChange}
                                        options={this.state.campaignOptions}
                                />
                                {this.state.selectedOptionErr &&
                                    <span className="text-danger pl-3 pt-1">{this.state.selectedOptionErr}</span>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                {this.state.selectedOption && (
                                    <Button variant={"danger"} onClick={this.onUnAssignCampaign}>
                                        {i18next.t("unassign")}
                                    </Button>
                                )}

                                <Button variant={"primary"}
                                        onClick={this.onAssignCampaign}>{i18next.t("assign")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Body>
                                        <Alert show={this.state.commonAlert} variant={this.state.commonAlertVariant}
                                               className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlertMessage}</h6>
                                        </Alert>
                                        {(this.state.granularPermissions.includes('r')) && <DataTable
                                            columns={this.state.columns}
                                            data={this.state.devicesList}
                                            noDataComponent={i18next.t("no_records_to_display")}
                                        />}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Devices;